.colorStyle {
  color: #00b8e2;
}

/* ******** ANA MENÜ  */
/*ul.nav-dropdown-items.have-child {
  li {
    ul {
      li {
        margin-left: 15px;
      }
    }
  }
}




.sidebar {
  .sidebar-nav {
    width: 240px;
    background-color: #463f3e;
  }

  .nav {
    width: 240px;
    //    background-color: #665c5b;
  }
}

.sidebar-fixed {
  .sidebar {
    width: 240px;
    background-color: #463f3e;
  }
}

html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .main,
html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .app-footer,
html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .main,
html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .app-footer {
  margin-left: 220px;
}

html:not([dir="rtl"]) .sidebar {
  margin-left: -220px;
}*/

/* ****** BrandCamp ******* */
.brandCamp {
  padding: 10px 18px 10px 0;
  font-size: 30px;
  font-weight: 200;
  letter-spacing: 0.5px;
  line-height: 0px;
  display: -webkit-flex;
  display: flex;
  background-color: #dedddc;
}

.breadcrumb {
   padding: 10px 18px 0px 5px;
   font-size: 30px;
   font-weight: 200;
   letter-spacing: 0.5px;
   line-height: 0px;
   display: -webkit-flex;
   display: flex;
   border-bottom: 0px solid #c8ced3;
   background-color: #dedddc;
 }

.breadcrumb-item {
  color: #66C1BE;
}

/* **** Body *************/
.app-body {
  background-color: #dedddc;
  font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/* **** File Browser ***** */

/*input[type="file"] {
  display: none;
}*/

.custom-input-file {
  display: none;
}


.custom-file {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  color: #a39f9d;
  background: 0 0;
  border: 2px solid #d2d0cf;
  cursor: pointer;
}


/* **** Action Button ***** */
.button-view {
  color: #fff;
  background: linear-gradient(to bottom, #685f5d, #5b5251) #5f5755;
  background-color: #685f5d;
  font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
  border-color: #b9b7b5;
  &:hover, &:focus, &:active {
    background: linear-gradient(to bottom, #685f5d, #5b5251) #5f5755;
    background-color: #b0aeab;
    color: #fff;
    border-color: #b0aeab;
    box-shadow: none;
  }

}

.button-view-outline {
  color: #a39f9d;
  background: 0 0;
  border: 2px solid #d2d0cf

}

.button-action {
  color: #fff;
  background: linear-gradient(to bottom, #bcbab8, #b0aeab);
  background-color: #b9b7b5;
  font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
  border-color: #b9b7b5;
  &:hover, &:focus, &:active {
    background: linear-gradient(to bottom, #b4b2b0, #a3a09d);
    background-color: #b0aeab;
    color: #fff;
    border-color: #b0aeab;
    box-shadow: none;
  }

}

.button-action-outline {
  color: #a39f9d;
  background: 0 0;
  border: 2px solid #d2d0cf

}

.grid-button-action-outline {
  color: #a39f9d;
  background: 0 0;
  border: 2px solid #d2d0cf;
  width:40px;
  height:35px ;

}

.filter-button-delete {
  color: #f86c6b;
  background-color: #f4f4f4;

}


.pull-right {
  float: right !important;
}

.pull-center {
  display: flex;
  justify-content: center;
  align-items: center;

}


.decimalFormat
{
  text-align: right;
}

.card {
  background-color: #fdfdfd;

}

.card-header {
  height: 45px !important;

}

.account-header {
  display: flex;
  p {
    margin-left: 0;
    padding-right: 20px;
    padding-left: 20px;
    font-size: 30px;
    font-weight: 200;
    letter-spacing: 0.5px;
    line-height: 0px;
    display: -webkit-flex;
    display: flex;
    border-bottom: 0px solid #c8ced3;
    span {
      color: #a39f9d;
      font-size: 42px;
    }
  }
  h3 {
    white-space: nowrap;
    line-height: 42px;
    letter-spacing: .5px;
    font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    font-weight: 400;
  }
}

.account-sidebar {
  background-color: #e4e7ea;
  border-radius: 10px;
}

.imgCropPreview {
  font-family: sans-serif;
  text-align: center;
}

.todo-tasklist-item {
  background: #f6fbfc;
  padding: 10px;
  margin-bottom: 15px;
  overflow: hidden;
}
.todo-tasklist-item-border-green {
  border-left: #3faba4 2px solid;
}

.todo-tasklist-item-border-red {
  border-left: #d05454 2px solid;
}



/* SYSNCFUSION Avatar image ve Card  */

.sample_container.card_sample {
  max-width: 300px;
  margin: auto;
  min-height: 400px;
}

/* Card Customizations */

.sample_container.card_sample .e-custom-card {
  position: relative;
  margin-top: 80px;
  overflow: visible;
  border-radius: 15px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  transition: 0.2s;
  border-width: 1px;
  padding: 1px;
}

.e-custom-card.e-card:hover {
  border-width: 1px;
  padding: 1px;
}

/*.e-custom-card :nth-child(2) .e-card-header-title.name {
  margin-top: 20px;
}*/

.sample_container p.avatar-content {
  line-height: 20px;
  font-family: inherit;
}


.sample_container.card_sample .e-custom-card .e-card-header {
  text-align: left;
}


.sample_container.card_sample .e-custom-card .e-avatar {
  font-size: 40px;
  position: absolute;
  top: calc(0% - 1.5em);
  left: calc(50% - 1.5em);
  box-shadow: 0 16px 28px -8px rgba(0, 0, 0, .36), 0 4px 15px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 0, 0, .2);
}

/*.sample_container.card_sample .e-card.e-custom-card :nth-child(3) {
  padding: 12px 0px 20px 0px;
  height: 70px;
}*/

/*.sample_container.card_sample .e-custom-card.e-card .e-card-header .e-card-header-caption .e-card-header-title {
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  color: rgba(83, 101, 132, 0.65);
  text-shadow: 0 0 0.1px;
}

.sample_container.card_sample .e-custom-card.e-card .e-card-header .e-card-header-caption .e-card-sub-title {
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  color: rgba(86, 90, 97, 0.65);
  text-shadow: 0 0 0.1px;
}*/

.sample_container.card_sample .e-custom-card.e-card .e-card-content {
  overflow: visible;
  width: auto;
  margin: -5px 20px 0 20px;
  word-spacing: 1px;
}

.sample_container.card_sample .avatar-content {
  color: rgba(250, 200, 130, 0.6);
  margin: 0 auto;
  text-align: center;
  color: rgb(94, 94, 94);
  border: none;
  padding: 0;
  font-size: 14px;
}

.avatar-content {
  margin-bottom: 0;
}

.sample_container.card_sample {
  width: 100%;
}

.sample_container.card_sample .sample_container .name {
  margin-top: 10px;
}

.highcontrast .e-custom-card.e-card .avatar-content {
  color: rgba(255, 255, 255, 0.84);
}

.highcontrast .sample_container.card_sample .e-custom-card.e-card .e-card-header .e-card-header-caption .e-card-sub-title {
  color: rgba(255, 255, 255, 0.45);
}

.highcontrast .sample_container.card_sample .e-custom-card.e-card .e-card-header .e-card-header-caption .e-card-header-title {
  color: rgba(255, 255, 255, 0.84);
}


