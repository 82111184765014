/*.nav-tabs {
  padding: 10px 8px 0 0;
}*/
/*.nav nav-tabs {
  border: none;
  margin: 0;
}*/

/*.nav-tabs .nav-item {
  min-width: 90px;
  padding: 0;
  margin: 0;
}*/
.nav-tabs .nav-item .nav-link.active {
  border-top: 3px solid #66C1BE;
  margin-top: 0;
  position: relative
}

/*.nav-tabs .nav-item .nav-link {
  border: none;
  margin:0;
}*/
/*.nav-tabs .nav-item .nav-link span .badge {
  margin-left: -8px;
  margin-top: -18px;
  display: inline-block;
  min-width: 9px;
  padding: 3px;
  font-size: 10px;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: rgba(208, 72, 54, 0.88);
  border-radius: 85px;
}*/

/*.tab-content {
  border-top: 1px solid #c8ced3 !important;
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
}*/
