.grid-toolbar {
  outline: none !important;
  margin: 0;
  flex: 0 1 auto;
  background-color: #f4f4f4;
  min-height: 42px;
  height: 42px;
  width: 100%;
  .grid-toolbar-container {
    padding-left: 0;
    float: left;
    .grid-toolbar-body {
      background-color: #f4f4f4;
      margin-left: 0;
      margin-right: 0;
      height: 42px;
      color: #387db2;
      .toolbar-list {
        margin: 0 1rem 0 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-shrink: 0;
        color: #387db2;
        .toolbar-list-item {
          //font-family: 'Segoe UI Regular', 'SegoeUI', 'Segoe UI' !important;
          &:hover {
            background-color: #4494c51a;
            .list-icon {
              color: #075fa2;
            }
            .list-item-title {
              color: #075fa2;
            }
          }
          .list-button {
            display: flex;
            padding-right: 10px;
            line-height: 1rem;
            padding-left: 10px;
            height: 42px;
            background-color: transparent;
            align-items: center;
            border-width: 0;
            cursor: pointer;
            .list-icon {
              line-height: 1.5rem;
              margin-right: 5px;
              font-size: 14px;
              color: #387db2;
            }
            .list-item-title {
              max-width: 150px;
              font-size: 15px;
              line-height: 1.5rem;
              text-overflow: ellipsis;
              white-space: nowrap;
              color: #387db2;
              //font-family: 'Segoe UI Regular', 'SegoeUI', 'Segoe UI' !important;
              overflow: hidden;
            }
            .list-dropdown {
              display: unset;
              margin-left: 5px;
              button.list-dropdown-button {
                background-color: transparent;
                border: none;
                outline: none;
                color: #387db2;
                border-left: 1px solid #387db2;
                border-radius: unset;
                i {
                  font-size: 22px;
                  line-height: 1.5rem;
                  margin: auto;
                }
              }
            }
          }
        }
      }
    }
  }
}
