
//Kasa Hesabı alındı
.accountReceived {
  background-color: #4dbc74;
}

//Kasa Hesabı alınmmaış
.notAccountReceived {
  background-color: #f86b6b;
}
//Z Raporu alınmamış
.notZReportReceived {
  background-color: #62c2de
}

.e-attr {
  background: #d7f0f4;
}
